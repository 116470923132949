$theColor: #3dbaff;
.App {

}

.contactForm {
  padding: 1em;

  .btn_back {
    margin-left: .5em;
  }
}

header {
  padding: .5em;
  background: darken($theColor, 30%);

  .logo {
    vertical-align: middle;
    display: inline-block;
    svg {
      vertical-align: middle;
      height: 2em;
    }
  }

  h1 {
    color: white;
    margin: 0;
    text-align: left;
    font-size: 1.7rem;
  }
}

.about_help {
  padding: 1em;
}

.disclaimer {
  font-size: .8em;
  color: #555;
}

h2 {
  font-size: 1.5rem;
}

.results-breakdown {

  margin-bottom: 2em;

  td, th {
    text-align: right;
  }

  tr.table-titles {
    th {
      text-align: center;
    }
  }
  h2 {
    cursor: pointer;
    padding-left: 15px;
  }

  .row {
    &.hidden {
      transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s;
      transform: translateX(-50px);
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }

    &.visible {
      transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s;
      transform: translateX(0px);
      max-height: 400px;
      overflow: hidden;
    }
  }
}

.results-container {
  padding: 1em;
}

.result {
  font-size: 1.4em;
  text-align: right;
  border-bottom: 1px solid #ddd;
  color: black;
  padding: 5px;
  margin-bottom: 5px;

  &:last-child {
    border-bottom: none;
  }

  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
    font-size: .8em;
    font-weight: 200;
  }

}

.salary-input {
  padding: .5em;
  padding-top: 1em;
  background: $theColor;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: white;

  .choose-annual {
    margin-bottom: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: top;
  }
  .salary-container {
    display: inline-block;
  }

  select {
    border: none;
    padding: .2em;
    text-decoration: dashed;
    border: none;
    background-color: lighten($theColor, 10%);
    color: white;
    option {
      color: #333;
    }
  }

  input {
    margin-left: .4em;
    font-size: 1.3em;
    padding: .2em .5em;
    border: none;
    width: 6em;
  }

  label {
    span {
      margin-left: .2em;
      vertical-align: top;
    }
  }
}

.thirteenth {
  text-align: center;
}

.container {
  padding: 0;
}

footer {
  background: darken($theColor, 30%);
  padding: 1em;
  color: white;

  a {
    color: white;
    text-decoration: underline;
    &:hover {
      color: #aff;
    }
  }
}

@media (max-width: 321px) {
  header {
    h1 {
      font-size: 1.5rem;
    }
  }

}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .3em;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  transition: transform .5s;

  &.open {
    transition: transform .5s;
    transform: rotate(180deg);
  }
}

