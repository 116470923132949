body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.contactForm {
  padding: 1em; }
  .contactForm .btn_back {
    margin-left: .5em; }

header {
  padding: .5em;
  background: #0069a3; }
  header .logo {
    vertical-align: middle;
    display: inline-block; }
    header .logo svg {
      vertical-align: middle;
      height: 2em; }
  header h1 {
    color: white;
    margin: 0;
    text-align: left;
    font-size: 1.7rem; }

.about_help {
  padding: 1em; }

.disclaimer {
  font-size: .8em;
  color: #555; }

h2 {
  font-size: 1.5rem; }

.results-breakdown {
  margin-bottom: 2em; }
  .results-breakdown td, .results-breakdown th {
    text-align: right; }
  .results-breakdown tr.table-titles th {
    text-align: center; }
  .results-breakdown h2 {
    cursor: pointer;
    padding-left: 15px; }
  .results-breakdown .row.hidden {
    transition: max-height .5s, opacity .2s .5s, -webkit-transform .5s ease-out .5s;
    transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s;
    transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s, -webkit-transform .5s ease-out .5s;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
    max-height: 0;
    overflow: hidden; }
  .results-breakdown .row.visible {
    transition: max-height .5s, opacity .2s .5s, -webkit-transform .5s ease-out .5s;
    transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s;
    transition: max-height .5s, transform .5s ease-out .5s, opacity .2s .5s, -webkit-transform .5s ease-out .5s;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    max-height: 400px;
    overflow: hidden; }

.results-container {
  padding: 1em; }

.result {
  font-size: 1.4em;
  text-align: right;
  border-bottom: 1px solid #ddd;
  color: black;
  padding: 5px;
  margin-bottom: 5px;
  position: relative; }
  .result:last-child {
    border-bottom: none; }
  .result > div {
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
    font-size: .8em;
    font-weight: 200; }

.salary-input {
  padding: .5em;
  padding-top: 1em;
  background: #3dbaff;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: white; }
  .salary-input .choose-annual {
    margin-bottom: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: top; }
  .salary-input .salary-container {
    display: inline-block; }
  .salary-input select {
    border: none;
    padding: .2em;
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    border: none;
    background-color: #70ccff;
    color: white; }
    .salary-input select option {
      color: #333; }
  .salary-input input {
    margin-left: .4em;
    font-size: 1.3em;
    padding: .2em .5em;
    border: none;
    width: 6em; }
  .salary-input label span {
    margin-left: .2em;
    vertical-align: top; }

.thirteenth {
  text-align: center; }

.container {
  padding: 0; }

footer {
  background: #0069a3;
  padding: 1em;
  color: white; }
  footer a {
    color: white;
    text-decoration: underline; }
    footer a:hover {
      color: #aff; }

@media (max-width: 321px) {
  header h1 {
    font-size: 1.5rem; } }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .3em;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s; }
  .caret.open {
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

